import { render, staticRenderFns } from "./SectionExploreBuilding.vue?vue&type=template&id=2b098ca9&"
import script from "./SectionExploreBuilding.vue?vue&type=script&lang=js&"
export * from "./SectionExploreBuilding.vue?vue&type=script&lang=js&"
import style0 from "./SectionExploreBuilding.vue?vue&type=style&index=0&id=2b098ca9&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonAtomTitle: require('/app/components/atoms/common/AtomTitle.vue').default,OrganismsCommonOrganismExplore: require('/app/components/organisms/common/OrganismExplore.vue').default})
